import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// !IMPORTANTE https://github.com/stereobooster/react-snap/issues/240
import { hydrate, render } from "react-dom";
import './styles.css';

const firebaseConfig = process.env.REACT_APP_ENV === 'production' ? {
    apiKey: "AIzaSyDmco_rejcvJx8XqpOfXlviFm8KG8df0lQ",
    authDomain: "hope-navigation-center.firebaseapp.com",
    databaseURL: "https://hope-navigation-center.firebaseio.com",
    projectId: "hope-navigation-center",
    storageBucket: "hope-navigation-center.appspot.com",
    messagingSenderId: "1052249602163",
    appId: "1:1052249602163:web:66f3c2ced2be506ecfd206",
    measurementId: "G-8KT93C6QSX"
} : {
    apiKey: "AIzaSyAksxb3UP910P435R6XHL1Zj9PdOKj5dwA",
    authDomain: "hope-dev-bf2f2.firebaseapp.com",
    databaseURL: "https://hope-dev-bf2f2.firebaseio.com",
    projectId: "hope-dev-bf2f2",
    storageBucket: "hope-dev-bf2f2.appspot.com",
    messagingSenderId: "801507540204",
    measurementId: "xx"
};

firebase.initializeApp(firebaseConfig);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
