import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import firebase from 'firebase/app';
import 'firebase/functions';
import validate from 'validate.js';
import {Helmet} from "react-helmet";

const constraints = {
    name: {
        presence: {  allowEmpty: false }
    },
    email: {
        presence: {  allowEmpty: false },
        email: true
    },
    subject: {
        presence: {  allowEmpty: false }
    },
    message: {
        presence: {  allowEmpty: false }
    }
};

const Contact = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [formErrors, setFormErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const submitForm = evt => {
        const validacion = validate(formValues, constraints);

        setFormErrors({...validacion});

        if( !validacion ){
            setIsLoading(true)
            //firebase.functions().useFunctionsEmulator('http://localhost:5001');
            const sendContactMail = firebase.functions().httpsCallable('sendContactMail');
            sendContactMail({
                name: formValues.name,
                email: formValues.email,
                subject: formValues.subject,
                message: formValues.message,
            })
            .then(evt => {
                setIsLoading(false)
                alert('Thanks for contact us.')
            })
            .catch(evt => {
                setIsLoading(false)
                alert('Failed to send your message.')
            })
        }
    } 

    return (
        <section className="py-5">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Safe Parking</title>
            </Helmet>
            <Container>
                <Row>
                    <Col md={8} className="ml-auto mr-auto">
                        <h1 className={'mb-5'}>Contact us to get more information about available Safe Parking spaces</h1>

                        <Form>
                            <Form.Group className="mb-4">
                                <Form.Label>Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Name" 
                                    onChange={evt => {
                                        setFormValues({
                                            ...formValues,
                                            name: evt.nativeEvent.target.value
                                        })
                                    }} 
                                    isInvalid={formErrors.name}
                                />
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="email@email.com" 
                                    onChange={evt => {
                                        setFormValues({
                                            ...formValues,
                                            email: evt.nativeEvent.target.value
                                        })
                                    }} 
                                    isInvalid={formErrors.email}
                                />
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Subject" 
                                    onChange={evt => {
                                        setFormValues({
                                            ...formValues,
                                            subject: evt.nativeEvent.target.value
                                        })
                                    }} 
                                    isInvalid={formErrors.subject}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword" className="mb-4">
                                <Form.Label>Message</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows="3" 
                                    onChange={evt => {
                                        setFormValues({
                                            ...formValues,
                                            message: evt.nativeEvent.target.value
                                        })
                                    }} 
                                    isInvalid={formErrors.message}
                                />
                            </Form.Group>

                            <Button 
                                variant="secondary" 
                                type="button" 
                                className="text-white" 
                                onClick={submitForm}
                                disabled={isLoading}
                            >Send</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Contact;