import React from 'react';
import styles from './Header.module.scss';
import Logo from '../../assets/logo.svg';
import { Route, Link, NavLink } from "react-router-dom";
import clsx from 'clsx';

const Header = () => {
	return (
		<header className={styles.Header}>
			<Link to={'/'}><img src={Logo} className={styles.Logo} alt={'Hope Navigation Center'} /></Link>
			<nav className={styles.Navigation}>
				<ul className={styles.Menu}>
					<Route 
						path={'/'} 
						exact
						children={({match}) => (
							<li className={!!match ? styles.Active : null}><NavLink to={'/'}>Map</NavLink></li>
						)} 
					/>
					<Route 
						path={'/about-us/'} 
						children={({match}) => (
							<li className={!!match ? styles.Active : null}><NavLink to={'/about-us/'}>About us</NavLink></li>
						)} 
					/>
					{/* <Route 
						path={'/partners/'} 
						children={({match}) => (
							<li className={!!match ? styles.Active : null}><NavLink to={'/partners/'}>Partners</NavLink></li>
						)} 
					/> */}
					<Route 
						path={'/help/'} 
						children={({match}) => (
							<li className={!!match ? styles.Active : null}><NavLink to={'/help/'}>Help</NavLink></li>
						)} 
					/>
					<Route 
						path={'/safe-parking/'} 
						children={({match}) => (
							<li className={!!match ? styles.Active : null}><NavLink to={'/safe-parking/'}>Safe Parking</NavLink></li>
						)} 
					/>
				</ul>
				{/* <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target={'_blank'}>
					<input type="hidden" name="cmd" value="_s-xclick" />
					<input type="hidden" name="hosted_button_id" value="8ZR5765L5J9EA" />
					<button type="submit" name="submit" className={clsx(['btn', 'btn-primary', styles.BtnDonate])} title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button">Donate</button>
					<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
				</form> */}
				<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
					<input type="hidden" name="cmd" value="_s-xclick" />
					<input type="hidden" name="hosted_button_id" value="N9RQFNVCRBN8W" />
					{/* <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" /> */}
					<button type="submit" name="submit" className={clsx(['btn', 'btn-primary', styles.BtnDonate])} title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button">Donate</button>
					<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
				</form>
			</nav>
		</header>
	)
}

export default Header;