import React from 'react';
import styles from './App.module.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import ScreenMap from './screens/Map/ScreenMap';
import ScreenAbout from './screens/About/About';
import ScreenPartners from './screens/Partners/Partners';
import ScreenHelp from './screens/Help/Help';
import ScreenContact from './screens/Contact/Contact';
import ScreenLanding from './screens/Landing/Landing';
import GoogleAnalytics from './components/GoogleAnalytics';
import ScrollMemory from 'react-router-scroll-memory';
import { Responsive } from 'responsive-react';
import { isLaptopDevice, isBiggerThanLaptop } from 'responsive-react'


const App = () => {
	return(
		<Router>
			<ScrollMemory />
			<GoogleAnalytics />
			<div className={styles.App}>
				<Header />
				<Main>
					<Switch>
						<Route path="/" exact component={isLaptopDevice() || isBiggerThanLaptop() || navigator.userAgent === "ReactSnap" ? ScreenMap : ScreenLanding} />
						<Route path="/about-us/" exact component={ScreenAbout} />
						{/* <Route path="/partners/" exact component={ScreenPartners} /> */}
						<Route path="/help/" exact component={ScreenHelp} />
						<Route path="/safe-parking/" exact component={ScreenContact} />
					</Switch>
				</Main>
				<Footer />
			</div>
		</Router>
	)
}

export default App;
