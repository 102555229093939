import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/analytics';

export default function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        if(navigator.userAgent !== "ReactSnap"){
            firebase.analytics().logEvent('page_view', {
                page_location: pathname
            });
        }
    }, [pathname]);
    return null;
}