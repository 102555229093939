import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const initialState = {
    services: {
        list: [],
        map: {}
    },
    organizations: {
        list: [],
        map: {}
    },
    static: {
        list: [],
        map: {}
    }
}

export const StaticResourcesContext = React.createContext(initialState)

export const StaticResourcesProvider = ({children}) => {

    const reducer = (state, action) => {
        switch(action.type){
            case 'setServices':
                const listServices = action.payload || [];
                const mapServices = {};
                listServices.forEach(item => {
                    mapServices[item.id] = item;
                });
                return {
                    ...state,
                    services: {
                        list: listServices,
                        map: mapServices
                    }
                }
            case 'setOrganizations':
                const listOrganizations = action.payload || [];
                const mapOrganizations = {};
                listOrganizations.forEach(item => {
                    mapOrganizations[item.id] = item;
                });
                return {
                    ...state,
                    organizations: {
                        list: listOrganizations,
                        map: mapOrganizations
                    }
                }
            case 'setStatic':
                const list = action.payload || [];
                const map = {};
                list.forEach(item => {
                    map[item.id] = item;
                });
                return {
                    ...state,
                    static: {
                        list,
                        map
                    }
                }
            default:
                return { ...initialState }
        }
    }

    const [state, dispatch] = React.useReducer(reducer, initialState);
    
    React.useEffect(() => {
        firebase.firestore().collection('services').get()
        .then(querySnapshot => {
            if(querySnapshot.empty) return;
            const list = [];
            querySnapshot.forEach(document => {
                list.push({
                    ...document.data(),
                    id: document.id
                });
            });
            dispatch({
                type: 'setServices',
                payload: list
            })
        });

        firebase.firestore().collection('organizations').get()
        .then(querySnapshot => {
            if(querySnapshot.empty) return;
            const list = [];
            querySnapshot.forEach(document => {
                list.push({
                    ...document.data(),
                    id: document.id
                });
            });
            dispatch({
                type: 'setOrganizations',
                payload: list
            })
        });

        firebase.firestore().collection('static').get()
        .then(querySnapshot => {
            if(querySnapshot.empty) return;
            const list = [];
            querySnapshot.forEach(document => {
                list.push({
                    ...document.data(),
                    id: document.id
                });
            });
            dispatch({
                type: 'setStatic',
                payload: list
            })
        });
    }, []);

    return (
        <StaticResourcesContext.Provider value={{...state, dispatch}}>
            {children}
        </StaticResourcesContext.Provider>
    )
}