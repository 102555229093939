import { RRule } from 'rrule';
var moment = require('moment');
require('moment-timezone');

const makeRule = ({frequency, interval, byweekday, dtstart, timeZoneId}) => {
    return new RRule({
        freq: RRule[frequency], // Frequencia, por ejemplo RRule.WEEKLY
        interval: interval, // Intervalo, Ej: 1
        byweekday: byweekday, // lista con todos los dias de la semana que esta abierto
        dtstart: dtstart ? new Date(moment.tz(dtstart, 'YYYY-MM-DD', timeZoneId).utc().format('YYYY-MM-DD')) : null // Objeto Date con el dtstart (en locacion) en Time 00:00:00
    });
}

const makeWeekdays = (days) => {
    const byweekday = [];
    for (var key in days) {
        if( days[key] ){
            byweekday.push(RRule[key.toUpperCase()])
        }
    }
    return byweekday;
}

// {timezoneId, days, frequency, interval, dtstart, hours}
export const isOpen = ({timeZoneId, days, frequency, interval, dtstart, hours}) => {
    let isOpen = false;

    // Obtengo el codigo del día de hoy en la locacion (Ej. Sa)
    const dayOfWeek = moment.tz(timeZoneId).locale('en').format('dd');
    
    // genero una lista con todos los dias que esta abierto
    const byweekday = makeWeekdays(days);

    // Si el lugar no tiene dias abiertos, entonces esta cerrado
    if(byweekday.length === 0) return false;

    const rule = makeRule({
        frequency,
        interval,
        byweekday,
        dtstart,
        timeZoneId
    });

    const today = moment.utc( moment.tz(timeZoneId).format('YYYY-MM-DD') ); // El dia de hoy (en locacion) expresado en UTC con Time 00:00:00

    // si el dia de hoy devuelve un match en la regla, evaluamos los horarios
    if( rule.between(today.toDate(), today.toDate(), true ).length > 0 ){
        
        // para cada horario
        hours[dayOfWeek].forEach((hour, index) => {

            // obtengo la hora de apertura (timezone en LA)
            const hourOpen = String(hour.open).split(':');
            const open = moment.tz(`${moment.tz(timeZoneId).format('YYYY-MM-DD')}T${String(hourOpen[0]).padStart(2,'0')}:${String(hourOpen[1]).padStart(2,'0')}:00`, timeZoneId);

            // obtengo la hora de cierre (timezone en LA)
            const hourClose = String(hour.close).split(':');
            const close = moment.tz(`${moment.tz(timeZoneId).format('YYYY-MM-DD')}T${String(hourClose[0]).padStart(2,'0')}:${String(hourClose[1]).padStart(2,'0')}:00`, timeZoneId);

            if( moment().isBetween(open, close) ){
                isOpen = {
                    from: open.format('HH:mm'),
                    to: close.format('HH:mm')
                }
            }
        });
    }

    return isOpen;
}

// {days, frequency, interval, dtstart, timeZoneId}
export const getTimesOpen = ({days, frequency, interval, dtstart, timeZoneId}) => {

    // Date-related translations
    var spanish = {
        dayNames: ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sábado"],
        monthNames: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
    };

    // Strings
    var spanishStrings = {
        'every': 'cada',
        'until': 'hasta',
        'day': 'día',
        'days': 'dias',
        'week': 'semana',
        'weeks': 'semanas',
        'on': 'los'
    };

    const gettext = (id) => {
        return spanishStrings[id] || id;
    };

    const byweekday = makeWeekdays(days);

    const rule = makeRule({
        frequency,
        interval,
        byweekday,
        dtstart,
        timeZoneId
    });

    const toTextEn = rule.toText();
    const toTextEs = rule.toText(gettext, spanish);

    return {
        en: toTextEn.charAt(0).toUpperCase() + toTextEn.slice(1),
        es: toTextEs.charAt(0).toUpperCase() + toTextEs.slice(1)
    };
}


// {days, frequency, interval, dtstart, timeZoneId, hours}
export const getNextOpen = ({days, frequency, interval, dtstart, timeZoneId, hours}) => {
    let nextOpen = null;

    const byweekday = makeWeekdays(days);
    
    const rule = makeRule({
        frequency,
        interval,
        byweekday,
        dtstart,
        timeZoneId
    });

    const today = moment.utc(`${moment.tz(timeZoneId).format('YYYY-MM-DD')}T00:00:00`);

    const proximo = rule.after(today.toDate(), true);
    const siguiente = rule.after(today.toDate(), false);

    const proximoHours = hours[moment.utc(proximo).locale('en').format('dd')];
    const siguienteHours = hours[moment.utc(siguiente).locale('en').format('dd')];

    proximoHours.forEach(hour => {
        const hourOpen = String(hour.open).split(':');
        const open = moment.tz(`${moment.utc(proximo).format('YYYY-MM-DD')}T${String(hourOpen[0]).padStart(2,'0')}:${String(hourOpen[1]).padStart(2,'0')}:00`, timeZoneId);
        if( moment().isBefore(open) && nextOpen === null ){
            nextOpen = open;
        }
    });

    siguienteHours.forEach(hour => {
        const hourOpen = String(hour.open).split(':');
        const open = moment.tz(`${moment.utc(siguiente).format('YYYY-MM-DD')}T${String(hourOpen[0]).padStart(2,'0')}:${String(hourOpen[1]).padStart(2,'0')}:00`, timeZoneId);
        if( moment().isBefore(open) && nextOpen === null ){
            nextOpen = open;
        }
    });
    
    return nextOpen;
}