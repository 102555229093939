import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <div className={styles.Footer}>
            <span className={styles.Copy}>© Hope Navigation Center</span>
            <div className={styles.Links}>
                <a>Politicas de privacidad</a>
                <a>Condiciones de uso</a>
            </div>
        </div>
    )
}

export default Footer;