import React from 'react';
import styles from './Landing.module.scss';
import AppleBtn from '../../assets/landing/apple-store-button@2x.png';
import GoogleBtn from '../../assets/landing/google-store-button@2x.png';

const Landing = () => {
    return (
        <div className={styles.Landing}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="mb-4 text-secondary" style={{fontWeight: 600}}>An app for those who need</h2>
                        <p className="mb-4">Download the app Available for all devices</p>
                        <div className={styles.Buttons}>
                            <a href={'https://itunes.apple.com/us/app/hope-navigation-center/id1473219627'} target={'_blank'}><img src={AppleBtn} /></a>
                            <a href={'https://play.google.com/store/apps/details?id=org.theshowerofhope.navigationcenter'} target={'_blank'}><img src={GoogleBtn} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing;