import React from 'react';
import styles from './About.module.scss';
import { NavLink } from 'react-router-dom';
import IconMission from '../../assets/icons/IconMission.svg';
import IconVision from '../../assets/icons/IconVision.svg';
import IconValues from '../../assets/icons/IconValues.svg';
import ImagePeople from '../../assets/about/people@2x.png';
import IconService from '../../assets/icons/IconService.svg';
import IconService02 from '../../assets/icons/IconService02.svg';
import IconService03 from '../../assets/icons/IconService03.svg';
import IconService04 from '../../assets/icons/IconService04.svg';
import IconSafeParking from '../../assets/icons/IconSafeParking.svg';
import {Helmet} from "react-helmet";

const Box = ({icon, title, content}) => {
    return (
        <div className={styles.Box}>
            {icon}
            <h5 style={{fontWeight: 600}}>{title}</h5>
            <p>{content}</p>
        </div>
    )
}

const BoxService = ({icon, title, content}) => {
    return (
        <div className={styles.BoxService}>
            {icon}
            <h5 style={{fontWeight: 600}}>{title}</h5>
            <p>{content}</p>
        </div>
    )
}

const About = () => {
    return (

        <>
            {/* <section className={styles.Banner}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>We bring together a community of strategic partners to generate more value for the homeless population.</h1>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="container py-5">
                <div className="row">
                    <div className="col-md-4">
                        <Box
                            icon={<img src={IconMission} alt={'Mission'} />}
                            title={'Our mission'}
                            content={'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum sunt in culpa qui officia.'}
                        />
                    </div>
                    <div className="col-md-4">
                        <Box
                            icon={<img src={IconVision} alt={'Vision'}/>}
                            title={'Our vision'}
                            content={'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum sunt in culpa qui officia.'}
                        />
                    </div>
                    <div className="col-md-4">
                        <Box
                            icon={<img src={IconValues} alt={'Values'}/>}
                            title={'Our values'}
                            content={'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum sunt in culpa qui officia.'}
                        />
                    </div>
                </div>
            </section> */}

            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us</title>
            </Helmet>
            
            <section className="container py-5">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="pr-5">
                            <h2 className="mb-4" style={{fontWeight: 600}}>About us</h2>
                            <p className="mb-4 text-primary" style={{fontWeight: 600}}>Our organization continues to engage hundreds of volunteers every month and to partner with groups and organizations to bring relief to the most vulnerable populations in Los Angeles.</p>
                            <p>After delivering over half a million meals in Skid Row in 6 years, scaling up Mobile Shower Services up to 18 locations weekly in Los Angeles, and providing Safe Parking services, we wanted to connect our most vulnerable neighbors, housed and unhoused, with essential resources like food, clothing, and showers with this free resource guide.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src={ImagePeople} style={{width: '100%'}} alt={'About us'} />
                    </div>
                </div>
            </section>

            <section style={{backgroundColor: '#74AF0D'}}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12" style={{textAlign: 'center'}}>
                            <h1 className="mb-5" style={{color: 'white'}}>Services</h1>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-3">
                            <BoxService 
                                icon={<img src={IconService} alt={'Service'}/>}
                                title={'Showers & Health'}
                                content={''}
                            />
                        </div>
                        <div className="col-md-3">
                            <BoxService 
                                icon={<img src={IconService02} alt={'Service'} />}
                                title={'Clothes'}
                                content={''}
                            />
                        </div>
                        <div className="col-md-3">
                            <BoxService 
                                icon={<img src={IconService03} alt={'Service'} />}
                                title={'Food'}
                                content={''}
                            />
                        </div>
                        <div className="col-md-3">
                            <BoxService 
                                icon={<img src={IconSafeParking} alt={'Service'} />}
                                title={'Safe Parking'}
                                content={''}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row justify-content-center">
                            <NavLink to={"/"} className="btn btn-light text-secondary" style={{backgroundColor: 'white'}}>Explore</NavLink>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default About;