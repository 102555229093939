import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import styles from './ScreenMap.module.scss';
import { NavLink, useLocation, withRouter } from "react-router-dom";
import { withScriptjs, withGoogleMap, GoogleMap, OverlayView } from "react-google-maps";
import MapPin from '../../assets/pin.svg';
import MapPinClosed from '../../assets/pinClosed.svg';
import { isOpen, getNextOpen, getTimesOpen } from '../../utils/Hours';
import { CSSTransition } from 'react-transition-group';
import './styles.css';
import { StaticResourcesContext } from '../../context/StaticResources';
import mapStyles from './MapStyles.json';
import { StaticResourcesProvider } from '../../context/StaticResources';
import clsx from 'clsx';
import IconAlertPopup from '../../assets/icons/IconAlertPopup.svg';
import IconTelephone from '../../assets/icons/IconTelephone.svg';
import IconWebsite from '../../assets/icons/IconWebsite.svg';
import { Helmet } from "react-helmet";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const PopupMap = () => {
    const [data, setData] = React.useState(null);
    
    React.useEffect(() => {
        const watchStatic = firebase.firestore().collection('static').doc('popup_map').onSnapshot(snapshot => {
            if(snapshot.exists){
                const documentData = snapshot.data();
                setData({
                    ...documentData
                });
            }
        }, console.error);

        return watchStatic;
    }, []);
    
    return (
        <>
            {data && data.isActive ? <div className={styles.PopupMap}>
                <div className={styles.Text}>
                    <div className={styles.Title}>
                        <img src={IconAlertPopup} className={styles.Icon} />
                        {data.title_en}
                    </div>
                    <div className={styles.Content}>{data.content_en}</div>
                </div>
            </div> : null}
        </>
    )
    
}

const Sidebar = ({activeServiceId}) => {
    const [services, setServices] = React.useState(null);

    React.useEffect(() => {
        firebase.firestore().collection('services').get().then(querySnapshot => {
            if(!querySnapshot.empty){
                let servicesList = [];
                querySnapshot.forEach(document => {
                    servicesList.push({
                        id: document.id,
                        ...document.data()
                    });
                });
                setServices(servicesList);
            }
        });
    }, []);

    return (
        <div className={styles.Sidebar}>
            {services ? (
                <ul className={styles.MenuCategories}>
                    <li className={styles.Header}>What do you need</li>
                    {services.map(item => {
                        return <li key={item.id}><NavLink to={`?service=${item.id}`} className={activeServiceId === item.id ? styles.Selected : ''}>{item.label['en']}</NavLink></li>;
                    })}
                    <li style={{paddingRight: 50, color: '#74AF0D', borderBottom: 'none'}}>More places and services coming soon</li>
                </ul>
            ) : null}

            <PopupMap />
        </div>
    )
}

const FeaturedImage = ({image}) => {
    const [url, setUrl] = React.useState(null);

    React.useEffect(() => {
        if(image){
            firebase.storage().ref('uploads/' + image).getDownloadURL().then(url => {
                setUrl(url);
            });
        }
    }, [image]);

    return (
        <div className={styles.Thumbnail}>
            {url && <img src={url} className={styles.Image}  alt={''} />}
        </div>
    )
}

const PlaceDetails = ({placeId}) => {
    const [progress, setProgress] = React.useState(false);
    const [place, setPlace] = React.useState(null);
    const { services, organizations } = React.useContext(StaticResourcesContext);

    React.useEffect(() => {
        if(!placeId) return;
        setProgress(true);
        setPlace(null);
        firebase.firestore().collection('places').doc(placeId).get().then(document => {
            if(document.exists){
                const data = document.data();
                const ruleOptions = {
                    timeZoneId: data.timeZoneId,
                    days: data.days,
                    frequency: data.frequency,
                    interval: data.interval,
                    dtstart: data.dtstart,
                    hours: data.hours
                };
                setPlace({
                    ...data,
                    isOpen: isOpen(ruleOptions),
                    timesOpen: getTimesOpen(ruleOptions),
                    nextOpen: getNextOpen(ruleOptions)
                });
                firebase.analytics().logEvent('screen_view', {
                    'screen_name': data.title
                });
            }
            setProgress(false);
        });
    }, [placeId]);

    return (
        <div className={styles.PlaceDetails}>
            {!progress ? <>
                {place && <>
                    <FeaturedImage image={place.image} />

                    <div className={styles.TopInfo}>
                        <h5 className={styles.Title}>{place.title}</h5>
                        <p className={styles.Address}>{place.address}</p>
                        <a 
                            className={clsx(['btn', 'btn-secondary', styles.BtnDirections])}
                            href={`https://www.google.com/maps?saddr=My+Location&daddr=${place.location.latitude},${place.location.longitude}`}
                            target={'_blank'}
                        >
                            How to get there
                        </a>

                        {place.isOpen ? (
                            <div>
                                <span className={styles.OpenNow}><strong>OPEN NOW</strong></span> ({place.isOpen['from']} to {place.isOpen['to']})<br />
                                {place.timesOpen['en']}
                            </div> 
                        ) : (
                            <div>
                                <span className={styles.Closed}><strong>CLOSED </strong>
                                    {place.nextOpen ? <>
                                        (Open { place.nextOpen.format('ddd DD MMM HH:mm') }hs)
                                    </> : null }
                                </span>
                                
                                <br />
                                {place.timesOpen['en']}
                            </div>
                        )}
                    </div> 

                    <div className={styles.Content}>

                        {place.content_en && <p className={'mb-4'}>{place.content_en}</p>}
                        
                        {place.telephone || place.website ? <ul className={clsx([styles.Information, 'mb-4'])}>
                            <li><div className={styles.Title}>Information:</div></li>
                            {place.telephone && <li><div className={styles.Item}><img src={IconTelephone} className={styles.Icon} /> <a href={`tel:${place.telephone}`}>{place.telephone}</a></div></li>}
                            {place.website && <li><div className={styles.Item}><img src={IconWebsite} className={styles.Icon} /> <a href={`${place.website}`} target={'_blank'}>{place.website}</a></div></li>}
                        </ul> : null}
                        
                        {place.organizations.length > 0 && <ul className={clsx([styles.Organizations, 'mb-4'])}>
                            <li style={{borderBottom: 'none'}}><div className={styles.Title}>Organizations:</div></li>
                            <li>{place.organizations.map(item => {
                                return <span key={item} className={styles.Item}>{organizations.map && organizations.map[item] && organizations.map[item].label['en']}</span>
                            })}</li>
                        </ul>}

                        {place.services.length > 0 && <ul className={clsx([styles.Services, 'mb-4'])}>
                            <li style={{borderBottom: 'none'}}><div className={styles.Title}>Services:</div></li>
                            <li>{place.services.map(item => {
                                return <span key={item} className={styles.Item}>{services.map && services.map[item] && services.map[item].label['en']}</span>
                            })}</li>
                        </ul>}
                    </div>
                </>}
            </> : null}
        </div>
    )
}

const MyMap = withRouter(withScriptjs(withGoogleMap(({places, activePlaceId, history, location, onClick}) => {
    const query = useQuery();
    let map = null;

    React.useEffect(() => {
        if(!places) return;
        var bounds = new window.google.maps.LatLngBounds();
        places.forEach(marker => {
			bounds.extend(marker.location)
		});
		map.fitBounds(bounds);
    }, [places]);

    return (
        <>
            <GoogleMap 
                defaultZoom={15} 
                defaultCenter={{ 
                    lat: 34.0506754, 
                    lng: -118.2453707
                }} 
                options={{
                    styles: mapStyles,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    streetViewControl: false
                }}
                onClick={onClick}
                ref={component => map = component}
            >
                {places && places.map(item => (
                    <OverlayView 
                        key={item.id}
                        position={item.location}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div 
                            style={{
                                width: 24, 
                                cursor: 'pointer', 
                                transform: 'translate(-100%, -100%)'
                            }}
                            onClick={evt => {
                                query.set('place', item.id);
                                history.push(`${location.pathname}?${query.toString()}`)
                            }}
                        >
                            <img src={item.isOpen ? MapPin : MapPinClosed} width={24} alt={item.title} />
                            {activePlaceId === item.id ? <div style={{
                                position: 'absolute', 
                                backgroundColor: 'white',
                                whiteSpace: 'nowrap',
                                display: 'block',
                                transform: 'translate(-50%, 0)',
                                left: '50%',
                                padding: 5,
                                borderRadius: 5,
                                lineHeight: '1em',
                                border: 'solid 1px #76BC21',
                                color: 'black'
                            }}>{item.title}</div> : null}
                        </div>
                    </OverlayView>
                 ))}
            </GoogleMap>
            
            <CSSTransition 
                in={Boolean(activePlaceId)} 
                timeout={1000} 
                classNames="panel-details" 
                appear 
                unmountOnExit
            >
                <PlaceDetails 
                    placeId={activePlaceId} 
                />
            </CSSTransition>
        </>
    )
})));

const ScreenMap = ({history, location}) => {
    const query = useQuery();

    const activeService = query.get('service');
    const activePlace = query.get('place');
    const [places, setPlaces] = React.useState(null);
    const [loggedIn, setLoggedIn] = React.useState(false);
    
    React.useEffect(() => {
        if(navigator.userAgent !== "ReactSnap"){
            firebase.auth().signInAnonymously();
            firebase.auth().onAuthStateChanged(user => {
                if(user){
                    setLoggedIn(true);
                }else{
                    setLoggedIn(false);
                }
            });
        }
    }, []);
    

    React.useEffect(() => {
        if(!activeService){
            setPlaces(null);
            return
        };
        firebase.firestore().collection('places').where('services', 'array-contains', activeService).where('status', '==', 1).get().then(querySnapshot => {
            if(!querySnapshot.empty){
                let list = [];
                querySnapshot.forEach(item => {
                    const data = item.data();
                    list.push({
                        ...data,
                        id: item.id,
                        location: {
                            lat: data.location.latitude,
                            lng: data.location.longitude
                        },
                        isOpen: isOpen({
                            timeZoneId: data.timeZoneId,
                            days: data.days,
                            frequency: data.frequency,
                            interval: data.interval,
                            dtstart: data.dtstart,
                            hours: data.hours
                        })
                    })
                });
                setPlaces([...list]);
            }else{
                setPlaces([]);
            }
        });

    }, [activeService]);
    
    return loggedIn ? (
        <section className={styles.ScreenMap}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hope Navigation Center</title>
            </Helmet>

            <StaticResourcesProvider>
                <Sidebar 
                    activeServiceId={activeService} 
                />
                <MyMap 
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDmco_rejcvJx8XqpOfXlviFm8KG8df0lQ&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div className={styles.MapContainer} />}
                    mapElement={<div className={styles.MapElement} />}
                    places={places}
                    activePlaceId={activePlace}
                    onClick={evt => {
                        query.delete('place');
                        history.push(`${location.pathname}?${query.toString()}`)
                    }}
                />
            </StaticResourcesProvider>
        </section>
    ) : null
}

export default ScreenMap;